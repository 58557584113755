<template>
  <!-- <div>浏览器信息:{{ua}}</div>
  <div>会员姓名:{{userName}}</div>
  <div>会员电话:{{userPhone}}</div>
  <div>店铺名称:{{storeName}}</div> -->
  <div v-if="userDevice" style="padding-top: 40px;">
    <div style="padding-left:30px;margin-bottom: 12px;">微信浏览器无法下载文件</div>
    <div style="padding-left:30px;margin-bottom: 12px;">请点右上角 <van-icon name="ellipsis" /> 图标</div>
    <div style="padding-left:30px;margin-bottom: 12px;">选择 在浏览器打开 </div>
  </div>
  <div v-else style="padding-top: 40px;text-align: center;">
    <div style="margin-bottom: 18px;">{{`${storeName}-${userName}-${userPhone} 分润统计明细`}}</div>
    <div style="margin-bottom: 18px;"><span @click="downloadExcel">已开始自动下载，若下载失败，请重试</span></div>
    <van-button style="margin:0 auto;" type="primary" @click="downloadExcel">点击下载</van-button>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { getBlod } from '@/api/util.js'
export default {
  setup () {
    const useRoute = inject('useRoute')
    const state = reactive({
      startCreateTime: useRoute.query.startCreateTime,
      endCreateTime: useRoute.query.endCreateTime,
      userId: useRoute.query.userId,
      storeId: useRoute.query.storeId,
      userName: useRoute.query.userName,
      storeName: useRoute.query.storeName,
      userPhone: useRoute.query.userPhone,
      userDevice: true,
      ua: ''
    })
    const isWechat = () => {
      const ua = window.navigator.userAgent.toLowerCase()
      state.ua = ua
      if (/MicroMessenger/i.test(ua)) {
        return true
      } else {
        return false
      }
    }
    const isIosOrAndroid = () => {
      const u = navigator.userAgent
      // android终端
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 1
      }
      // ios终端
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        return 2
      }
      return 0
    }
    const downloadExcel = () => {
      if (!isWechat()) {
        state.userDevice = false
        if (isIosOrAndroid() === 2) {
          const data = {
            storeId: state.storeId,
            startCreateTime: state.startCreateTime ? state.startCreateTime : '',
            endCreateTime: state.endCreateTime ? state.endCreateTime : '',
            userId: state.userId
          }
          getBlod('/staffcommission.sum.detailsexpor', data).then(res => {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })// for .xlsx files
            const URL = window.URL || window.webkitURL
            const url = URL.createObjectURL(blob)
            const ele = document.createElement('a')
            ele.style.display = 'none'
            ele.href = url
            ele.download = `${state.storeName}-${state.userName}-${state.userPhone} 分润统计明细`
            document.body.appendChild(ele)
            const evt = document.createEvent('MouseEvents')
            evt.initEvent('click', true, true)
            ele.dispatchEvent(evt)
            document.body.removeChild(ele)
            URL.revokeObjectURL(ele.href)
          })
        } else {
          const url = process.env.VUE_APP_URL + `/staffcommission.sum.detailsexpor?storeId=${state.storeId}&userId=${state.userId}&startCreateTime=${state.startCreateTime}&endCreateTime=${state.endCreateTime}`
          window.location.href = url
        }
      } else {
        state.userDevice = true
      }
    }
    const init = () => {
      downloadExcel()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      isWechat,
      downloadExcel,
      isIosOrAndroid
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}

</style>
